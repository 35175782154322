#root {
  height: 100vh;
  max-height: 100vh;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

* {
  box-sizing: border-box
}

html {
  color-scheme: dark
}

.hidden {
  display: none!important;
}

#__next,body,html,main {
  width: 100%;
  height: 100%;
  margin: 0
}

.header {
  max-width: 500px;
  padding-inline:2rem}

.header>img {
  display: block;
  margin: auto;
  max-width: 100%
}

.header>h2 {
  font-family: TWK Everett,sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 144%
}

.header>h2,footer {
  text-align: center;
  color: hsla(0,0%,100%,.6)
}

footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 1.5rem 2rem;
  background-color: var(--lk-bg);
  border-top: 1px solid hsla(0,0%,100%,.15)
}

footer a,h2 a {
  color: #ff6352;
  -webkit-text-decoration-color: #a33529;
  text-decoration-color: #a33529;
  text-underline-offset: .125em
}

footer a:hover,h2 a {
  -webkit-text-decoration-color: #ff6352;
  text-decoration-color: #ff6352
}

h2 a {
  text-decoration: none
}

[data-lk-theme=default] {
  color-scheme: dark;
  --lk-bg: #111;
  --lk-bg2: #1e1e1e;
  --lk-bg3: #2b2b2b;
  --lk-bg4: #373737;
  --lk-bg5: #444;
  --lk-fg: #fff;
  --lk-fg2: #f5f5f5;
  --lk-fg3: #ebebeb;
  --lk-fg4: #e0e0e0;
  --lk-fg5: #d6d6d6;
  --lk-border-color: hsla(0,0%,100%,.1);
  --lk-accent-fg: #fff;
  --lk-accent-bg: #1f8cf9;
  --lk-accent2: #3396fa;
  --lk-accent3: #47a0fa;
  --lk-accent4: #5babfb;
  --lk-danger-fg: #fff;
  --lk-danger: #f91f31;
  --lk-danger2: #fa3343;
  --lk-danger3: #fa4756;
  --lk-danger4: #fb5b68;
  --lk-success-fg: #fff;
  --lk-success: #1ff968;
  --lk-success2: #33fa75;
  --lk-success3: #47fa83;
  --lk-success4: #5bfb90;
  --lk-control-fg: var(--lk-fg);
  --lk-control-bg: var(--lk-bg2);
  --lk-control-hover-bg: var(--lk-bg3);
  --lk-control-active-bg: var(--lk-bg4);
  --lk-control-active-hover-bg: var(--lk-bg5);
  --lk-connection-excellent: #06db4d;
  --lk-connection-good: #f9b11f;
  --lk-connection-poor: #f91f31;
  --lk-font-family: system-ui,-apple-system,"Segoe UI",Roboto,Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji";
  --lk-font-size: 16px;
  --lk-line-height: 1.5;
  --lk-border-radius: 0.5rem;
  --lk-box-shadow: 0 0.5rem 1.5rem rgba(0,0,0,.15);
  --lk-grid-gap: 0.5rem;
  --lk-control-bar-height: 126px;
}

.lk-button,.lk-chat-toggle,.lk-disconnect-button,.lk-start-audio-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: .5rem;
  padding: .625rem 1rem;
  color: var(--lk-control-fg);
  background-image: none;
  background-color: var(--lk-control-bg);
  border: 0;
  border-radius: var(--lk-border-radius);
  cursor: pointer;
  white-space: nowrap
}

.lk-button:not(:disabled):hover,.lk-chat-toggle:not(:disabled):hover,.lk-disconnect-button:not(:disabled):hover,.lk-start-audio-button:not(:disabled):hover {
  background-color: var(--lk-control-hover-bg)
}

.lk-button[aria-pressed=true],[aria-pressed=true].lk-chat-toggle,[aria-pressed=true].lk-disconnect-button,[aria-pressed=true].lk-start-audio-button {
  background-color: var(--lk-control-active-bg)
}

.lk-button[aria-pressed=true]:hover,[aria-pressed=true].lk-chat-toggle:hover,[aria-pressed=true].lk-disconnect-button:hover,[aria-pressed=true].lk-start-audio-button:hover {
  background-color: var(--lk-control-active-hover-bg)
}

.lk-button:disabled,.lk-chat-toggle:disabled,.lk-disconnect-button:disabled,.lk-start-audio-button:disabled {
  opacity: .5
}

.lk-button-group {
  display: inline-flex;
  align-items: stretch
}

.lk-button-group>.lk-button:first-child,.lk-button-group>.lk-chat-toggle:first-child,.lk-button-group>.lk-disconnect-button:first-child,.lk-button-group>.lk-start-audio-button:first-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0
}

.lk-button-group-menu {
  position: relative;
  flex-shrink: 0
}

.lk-button-group-menu>.lk-button,.lk-button-group-menu>.lk-chat-toggle,.lk-button-group-menu>.lk-disconnect-button,.lk-button-group-menu>.lk-start-audio-button {
  height: 100%;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0
}

.lk-button-group-menu>.lk-button:after,.lk-button-group-menu>.lk-chat-toggle:after,.lk-button-group-menu>.lk-disconnect-button:after,.lk-button-group-menu>.lk-start-audio-button:after {
  margin-left: 0
}

.lk-button-menu:after {
  display: inline-block;
  content: "";
  width: .5em;
  height: .5em;
  margin-top: -.25rem;
  margin-left: .5rem;
  border-left: .125em solid;
  border-bottom: .125em solid;
  transform: rotate(-45deg);
  transform-origin: center center
}

.lk-disconnect-button {
  --lk-control-fg: var(--lk-danger-fg);
  --lk-control-bg: var(--lk-danger);
  font-weight: 600
}

.lk-disconnect-button:not(:disabled):hover {
  --lk-control-hover-bg: var(--lk-danger2)
}

.lk-disconnect-button:not(:disabled):active {
  --lk-control-hover-bg: var(--lk-danger3)
}

.lk-chat-toggle {
  position: static
}

.lk-media-device-select:not(:last-child) {
  padding-bottom: .5rem;
  margin-bottom: .75rem;
  border-bottom: 1px solid var(--lk-border-color)
}

.lk-media-device-select li:not(:last-child) {
  margin-bottom: .25rem
}

.lk-media-device-select li>.lk-button {
  width: 100%;
  justify-content: start;
  padding-block:.5rem}

.lk-media-device-select li:not([data-lk-active=true])>.lk-button:not(:disabled):hover {
  background-color: var(--lk-bg3)
}

.lk-media-device-select [data-lk-active=false]>.lk-button:hover {
  cursor: pointer;
  background-color: rgba(0,0,0,.05)
}

.lk-media-device-select [data-lk-active=true]>.lk-button {
  color: var(--lk-accent-fg);
  background-color: var(--lk-accent-bg)
}

.lk-device-menu {
  width: -moz-max-content;
  width: max-content;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;
  min-width: 10rem;
  padding: .5rem;
  margin-bottom: .25rem;
  white-space: nowrap;
  background-color: var(--lk-bg2);
  border: 1px solid var(--lk-border-color);
  border-radius: .75rem;
  box-shadow: var(--lk-box-shadow)
}

.lk-device-menu-heading {
  padding: .25rem .5rem;
  font-weight: 700;
  opacity: .65
}

.lk-start-audio-button {
  color: var(--lk-accent-fg);
  background-color: var(--lk-accent-bg)
}

@media screen and (max-width: 600px) {
  .lk-start-audio-button {
    position:fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%)
  }
}

.lk-pagination-control {
  position: absolute;
  bottom: 1rem;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: stretch;
  background-color: var(--lk-control-bg);
  border-radius: var(--lk-border-radius);
  transition: opacity .15s ease-in-out;
  opacity: 0
}

.lk-pagination-control:hover {
  opacity: 1
}

.lk-pagination-control>.lk-button:first-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0
}

.lk-pagination-control>.lk-button:first-child>svg {
  transform: rotate(180deg)
}

.lk-pagination-control>.lk-button:last-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0
}

.lk-pagination-count {
  padding: .5rem .875rem;
  border-inline:1px solid var(--lk-bg)}

[data-lk-user-interaction=true].lk-pagination-control {
  opacity: 1
}

.lk-pagination-indicator {
  position: absolute;
  height: var(--lk-grid-gap);
  background-color: var(--lk-bg2);
  width: -moz-fit-content;
  width: fit-content;
  padding: .2rem .5rem;
  bottom: calc(var(--lk-grid-gap)/2);
  left: 50%;
  transform: translateX(-50%);
  border-radius: 2rem;
  opacity: 1;
  display: flex;
  gap: .2rem;
  align-items: center
}

.lk-pagination-indicator span {
  display: inline-block;
  width: .4rem;
  height: .4rem;
  border-radius: 9999999px;
  background-color: var(--lk-fg);
  opacity: .35;
  transition: opacity .2s linear
}

.lk-pagination-indicator span[data-lk-active] {
  opacity: .9
}

.lk-grid-layout {
  --lk-col-count: 1!important;
  --lk-row-count: 1!important;
  /*--lk-col-count: 1;*/
  /*--lk-row-count: 1;*/
  display: grid;
  grid-template-columns: repeat(var(--lk-col-count),minmax(0,1fr));
  grid-template-rows: repeat(var(--lk-row-count),minmax(0,1fr));
  grid-gap: var(--lk-grid-gap);
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  padding: var(--lk-grid-gap)
}

.lk-grid-item-empty {
  color: #ffffff;
  text-align: center;
  font-size: 18px;
  position: absolute;
  top: calc(50vh - 15px);
  left: calc(50vw - 83px); /* text "Inicializácia hovoru ..." ma +- 166px cize polovica je 83*/
  z-index: 2;
}

.lk-grid-layout[data-lk-pagination=true] {
  padding-bottom: calc(var(--lk-grid-gap)*2)
}

.lk-focus-layout {
  display: grid;
  grid-template-columns: 1fr 5fr;
  grid-gap: var(--lk-grid-gap);
  gap: var(--lk-grid-gap);
  width: 100%;
  max-height: 100%;
  padding: var(--lk-grid-gap)
}

.lk-focused-participant {
  position: relative
}

.lk-focused-participant .lk-pip-track {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 20%;
  height: auto
}

@media(max-width: 600px) {
  .lk-focus-layout {
    grid-template-columns:1fr;
    grid-template-rows: 5fr 1fr
  }

  .lk-carousel {
    order: 1
  }
}

.lk-carousel {
  max-height: 100%;
  display: flex;
  gap: var(--lk-grid-gap)
}

.lk-carousel>* {
  flex-shrink: 0;
  aspect-ratio: 16/10;
  scroll-snap-align: start
}

.lk-carousel[data-lk-orientation=vertical] {
  flex-direction: column;
  -ms-scroll-snap-type: y mandatory;
  scroll-snap-type: y mandatory;
  overflow-y: auto;
  overflow-x: hidden
}

.lk-carousel[data-lk-orientation=vertical]>* {
  --lk-height-minus-gaps: calc(100% - calc(var(--lk-grid-gap) * calc(var(--lk-max-visible-tiles) - 1)));
  height: calc(var(--lk-height-minus-gaps)/var(--lk-max-visible-tiles))
}

.lk-carousel[data-lk-orientation=horizontal] {
  -ms-scroll-snap-type: x mandatory;
  scroll-snap-type: x mandatory;
  overflow-y: hidden;
  overflow-x: auto
}

.lk-carousel[data-lk-orientation=horizontal]>* {
  --lk-width-minus-gaps: calc(100% - var(--lk-grid-gap) * (var(--lk-max-visible-tiles) - 1));
  width: calc(var(--lk-width-minus-gaps)/var(--lk-max-visible-tiles))
}

.lk-connection-quality {
  width: 1.5rem;
  height: 1.5rem
}

.lk-track-muted-indicator-camera,.lk-track-muted-indicator-microphone {
  position: relative;
  width: var(--lk-indicator-size,1rem);
  height: var(--lk-indicator-size,1rem);
  -webkit-margin-end: .25rem;
  margin-inline-end:.25rem;transition: opacity .25s ease-in-out
}

.lk-track-muted-indicator-camera[data-lk-muted=true] {
  opacity: .5
}

.lk-track-muted-indicator-microphone {
  --lk-bg: var(--lk-icon-mic)
}

.lk-track-muted-indicator-microphone[data-lk-muted=true] {
  opacity: .5
}

.lk-participant-name {
  font-size: .875rem
}

.lk-participant-media-video {
  width: 100%;
  height: 100%;
  -o-object-position: center;
  object-position: center;
  background-color: #000
}

.lk-participant-media-video,.lk-participant-media-video[data-lk-orientation=landscape] {
  -o-object-fit: contain!important;
  object-fit: contain!important;
}

.lk-participant-media-video[data-lk-orientation=portrait],.lk-participant-media-video[data-lk-source=screen_share] {
  -o-object-fit: contain;
  object-fit: contain;
  background-color: var(--lk-bg2)
}

.lk-participant-media-audio {
  width: auto;
}

[data-lk-facing-mode=user] .lk-participant-media-video[data-lk-local-participant=true][data-lk-source=camera] {
  transform: rotateY(180deg)
}

.lk-audio-visualizer>rect {
  fill: var(--lk-bg2);
  rx: .5rem;
}

.lk-audio-visualizer>g>rect {
  fill: var(--lk-fg);
  transition: height .1s ease-out,y .1s ease-out;
  rx: .25rem;
}

.lk-participant-tile {
  --lk-speaking-indicator-width: 2.5px;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: .375rem;
  overflow: hidden;
  border-radius: var(--lk-border-radius);
  z-index: 5;
}

.lk-participant-tile:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: var(--lk-border-radius);
  border: 0 solid var(--lk-accent-bg);
  transition-property: border opacity;
  transition-delay: .5s;
  transition-duration: .4s;
  pointer-events: none;
}

.lk-participant-tile[data-lk-speaking=true]:not([data-lk-source=screen_share]):after {
  transition-delay: 0s;
  transition-duration: .2s;
  border-width: var(--lk-speaking-indicator-width)
}

.lk-participant-tile .lk-focus-toggle-button {
  position: absolute;
  top: .25rem;
  right: .25rem;
  padding: .25rem;
  background-color: rgba(0,0,0,.5);
  border-radius: calc(var(--lk-border-radius)/2);
  opacity: 0;
  transition: opacity .2s ease-in-out;
  transition-delay: .2s;
  display: none;
}

.lk-participant-tile:focus .lk-focus-toggle-button,.lk-participant-tile:hover .lk-focus-toggle-button {
  opacity: 1;
  transition-delay: 0
}

.lk-participant-tile .lk-connection-quality {
  opacity: 1!important;
  transition: opacity .2s ease-in-out;
  transition-delay: .2s;
}

.lk-participant-tile .lk-connection-quality[data-lk-quality=poor],.lk-participant-tile:focus .lk-connection-quality,.lk-participant-tile:hover .lk-connection-quality {
  opacity: 1;
  transition-delay: 0;
}

.lk-participant-tile .lk-participant-placeholder {
  position: absolute;
  inset: 0;
  display: none!important;
  align-items: center;
  justify-content: center;
  background-color: var(--lk-bg2);
  opacity: 0;
  transition: opacity .2s ease-in-out;
  pointer-events: none;
  border-radius: var(--lk-border-radius)
}

.lk-participant-tile .lk-participant-placeholder svg {
  height: 100%;
  width: auto;
  padding: 10%
}

.lk-participant-tile[data-lk-video-muted=true][data-lk-source=camera] .lk-participant-placeholder {
  opacity: 1
}

.lk-participant-metadata {
  position: absolute;
  right: .25rem;
  bottom: .25rem;
  left: .25rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: .5rem;
  line-height: 1;
}

.lk-participant-metadata-item {
  display: flex;
  align-items: center;
  padding: 0!important;
  background-color: rgba(0,0,0,.5);
  border-radius: calc(var(--lk-border-radius)/2)
}

.lk-participant-metadata-item > span {
  display: none!important;
}

.lk-toast {
  position: fixed;
  top: .75rem;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  gap: .5rem;
  padding: .75rem 1.25rem;
  background-color: var(--lk-bg);
  border: 1px solid var(--lk-border-color);
  border-radius: var(--lk-border-radius);
  box-shadow: var(--lk-box-shadow)
}

.lk-spinner {
  animation: lk-rotate 2s linear infinite
}

@keyframes lk-rotate {
  0% {
    transform: rotate(0deg)
  }

  to {
    transform: rotate(359deg)
  }
}

.lk-room-container {
  background-color: var(--lk-bg);
  line-height: var(--lk-line-height);
  position: relative;
  width: 100%;
  height: 100%
}

.lk-room-container [class*=" lk-"],.lk-room-container [class^=lk-] {
  box-sizing: border-box
}

.lk-audio-conference {
  position: relative;
  width: 100%;
  height: 100%
}

.lk-audio-conference-stage {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(3,1fr);
  grid-gap: 10px;
  gap: 10px
}

.lk-chat {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: clamp(200px,55ch,60ch);
  background-color: var(--lk-bg2);
  border-left: 1px solid var(--lk-border-color)
}

.lk-chat-messages {
  width: 100%;
  flex-grow: 1;
  max-height: 100%;
  overflow: auto;
  justify-content: flex-end;
  margin-bottom: .5rem!important
}

.lk-chat-entry,.lk-chat-messages {
  display: flex;
  flex-direction: column;
  gap: .25rem
}

.lk-chat-entry {
  margin: 0 .25rem
}

.lk-chat-entry .lk-meta-data {
  font-size: .75rem;
  color: var(--lk-fg5);
  white-space: nowrap;
  padding: 0 .3rem;
  display: flex
}

.lk-chat-entry .lk-meta-data .lk-participant-name {
  margin-top: 1rem
}

.lk-chat-entry .lk-meta-data .lk-timestamp {
  margin-left: auto;
  align-self: flex-end
}

.lk-chat-entry .lk-message-body {
  border-radius: 15px;
  padding: .25rem .75rem;
  word-break: break-word;
  width: -moz-fit-content;
  width: fit-content
}

.lk-chat-entry[data-lk-message-origin=local] .lk-message-body {
  background-color: var(--lk-bg5)
}

.lk-chat-entry[data-lk-message-origin=remote] .lk-message-body {
  background-color: var(--lk-accent4)
}

.lk-chat-entry a {
  text-decoration: underline;
  color: inherit
}

.lk-chat-form {
  display: flex;
  gap: .75rem;
  padding: .75rem;
  border-top: 1px solid var(--lk-border-color);
  max-height: var(--lk-control-bar-height)
}

.lk-chat-form-input {
  width: 100%
}

@media(max-width: 600px) {
  .lk-chat {
    position:fixed;
    top: 0;
    right: 0;
    max-width: 100%;
    bottom: var(--lk-control-bar-height)
  }
}

.lk-control-bar {
  /*display: none!important;*/
  gap: .5rem;
  align-items: center;
  justify-content: center;
  padding: .75rem;
  /*border-top: 1px solid var(--lk-border-color);*/
  max-height: var(--lk-control-bar-height)
}

.lk-control-bar > button:not(.visible), .lk-control-bar > .lk-button-group:not(.visible) {
  display: none!important;
}

.lk-prejoin {
  line-height: var(--lk-line-height)
}

[data-lk-theme] {
  font-size: var(--lk-font-size);
  font-family: var(--lk-font-family);
  color: var(--lk-fg)
}

[data-lk-theme] button,[data-lk-theme] input {
  font: inherit;
  line-height: inherit
}

[data-lk-theme] .lk-button {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none
}

[data-lk-theme] .lk-list {
  list-style: none;
  margin: 0;
  padding: 0
}

[data-lk-theme] .lk-form-control {
  font-family: var(--lk-font-family);
  padding: .625rem 1rem;
  background-color: var(--lk-control-bg);
  border: 1px solid var(--lk-border-color);
  border-radius: var(--lk-border-radius)
}

.lk-prejoin {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  gap: 1rem;
  margin-inline:auto;background-color: var(--lk-bg);
  width: min(100%,480px);
  align-items: stretch;
}

.lk-prejoin .lk-video-container {
  position: relative;
  width: 100%;
  height: auto;
  aspect-ratio: 16/10;
  background-color: #000;
  border-radius: var(--lk-border-radius);
  overflow: hidden
}

.lk-prejoin .lk-video-container .lk-camera-off-note,.lk-prejoin .lk-video-container video {
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}

.lk-prejoin .lk-video-container video[data-lk-facing-mode=user] {
  transform: rotateY(180deg)
}

.lk-prejoin .lk-video-container .lk-camera-off-note {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  aspect-ratio: 16/10;
  background-color: #000;
  display: grid;
  place-items: center
}

.lk-prejoin .lk-video-container .lk-camera-off-note>* {
  height: 70%;
  max-width: 100%
}

.lk-prejoin .lk-audio-container {
  display: none
}

.lk-prejoin .lk-audio-container audio {
  width: 100%;
  height: auto
}

.lk-prejoin .lk-button-group-container {
  display: flex;
  flex-wrap: nowrap;
  gap: 1rem
}

.lk-prejoin .lk-button-group-container>.lk-button-group {
  width: 50%
}

.lk-prejoin .lk-button-group-container>.lk-button-group>.lk-button {
  justify-content: left
}

.lk-prejoin .lk-button-group-container>.lk-button-group>.lk-button:first-child {
  width: 100%
}

@media(max-width: 400px) {
  .lk-prejoin .lk-button-group-container {
    flex-wrap:wrap
  }

  .lk-prejoin .lk-button-group-container>.lk-button-group {
    width: 100%
  }
}

.lk-prejoin .lk-username-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  max-width: 100%
}

.lk-prejoin .lk-join-button {
  --lk-control-fg: var(--lk-accent-fg);
  --lk-control-bg: var(--lk-accent-bg);
  --lk-control-hover-bg: var(--lk-accent2);
  --lk-control-active-bg: var(--lk-accent3);
  --lk-control-active-hover-bg: var(--lk-accent4);
  background-color: var(--lk-control-bg)
}

.lk-prejoin .lk-join-button:hover {
  background-color: var(--lk-control-hover-bg)
}

.lk-focus-layout-wrapper,.lk-grid-layout-wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  height: calc(100% - var(--lk-control-bar-height))
  /*height: 100%!important;*/
}

.lk-grid-layout-wrapper {
  flex-direction: column;
  align-items: center
}

.lk-grid-layout-wrapper > div > .lk-participant-tile[data-lk-local-participant="false"] {
  display: none!important;
}

.lk-grid-layout-wrapper.client > div > .lk-participant-tile[data-lk-local-participant="false"] {
  display: none!important;
}
.lk-grid-layout-wrapper.client > div > .lk-participant-tile[data-lk-local-participant="true"] {
  display: flex!important;
}

.lk-grid-layout-wrapper.technician > div > .lk-participant-tile[data-lk-local-participant="true"] {
  display: none!important;
}
.lk-grid-layout-wrapper.technician > div > .lk-participant-tile[data-lk-local-participant="false"] {
  display: flex!important;
}

.lk-focus-layout-wrapper {
  align-items: stretch
}

.lk-video-conference {
  position: relative;
  display: flex;
  align-items: stretch;
  height: 100%
}

.lk-video-conference-inner {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%
}

.lk-control-bar-custom {
  position: fixed;
  bottom: 0;
  padding: 13px;
  width: 100%;
  text-align: center;
}

.lk-button-icon-custom {
  height: 20px;
  width: auto;
}
/*# sourceMappingURL=3fa35f50aee3a9b9.css.map*/

.wrapper {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: var(--lk-bg);
}

.wrapper .content {
  padding: 10px;
  background-color: var(--lk-accent-bg);
}

.wrapper .content .info-text {
  font-size: 18px;
  color: var(--lk-fg);
}

.wrapper .content .button {
  color: var(--lk-fg);
  background-color: #0099CC;
  padding: 0 10px;
  border: 1px solid #0099CC;
  border-radius: 4px;
  margin: 0 0 0 20px;
  font-size: 15px;
  height: 40px;
  line-height: 33px;
  cursor: pointer;
}

.wrapper .content .button:hover {
  background-color: #007AA3;
  border: 1px solid #007AA3;
}
